<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="titulo"
      color="dark"
      size="lg"
      @update:show="resetForm(false)"
      :close-on-backdrop="false"
      :show.sync="modalActive"
      class="modal-extended"
    >
      <CRow class="mt-2">
        <CCol sm="12">
          <div class="form-group form-row" rol="group">
            <label class="required col-form-label col-sm-12 col-lg-3 text-right" for="pais">{{$t('label.group')+' '+$t('label.supplie')}}</label>
              <div class="col-sm-12 col-lg-8 input-group-sm">
                <v-select 
                  :options="gruposOptions"
                  label="GpoSupplyName"
                  :placeholder="$t('label.select')"
                  :class="insumo.GpoSupplyId!=''?'select-client--correct':ValidationSelect?'select-client--error':''"
                  v-model="insumo.GpoSupplyId"
                  :reduce="option => option.value"
                  :getOptionLabel="option => option.label"
                />
              <div :class="isGrupoValid">
                {{$t('label.required')}}
              </div>
            </div>
          </div>
        </CCol>
        <CCol sm="12"> 
          <CInput
            v-model.trim="$v.insumo.SupplyName.$model"
            v-uppercase
            :horizontal="{ label:'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-8'}"
            :invalid-feedback="errorMessage($v.insumo.SupplyName)" 
            :is-valid="hasError($v.insumo.SupplyName)"
            :label="$t('label.name')"
            addLabelClasses="required text-right"
            :placeholder="$t('label.supplieName')"
            maxlength="100"
          >
          </CInput>
        </CCol>
        <CCol v-if="isEdit" sm="12">
          <CSelect
            :value.sync="insumo.Status"
            :is-valid="statusSelectColor"
            :label="$t('label.status')"
            addLabelClasses="text-right"
            :horizontal="{ label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-8'}"
            :options="statusOptions"
            class="mt-1"
          />
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          v-if="!isEdit"
          color="add"
          class="d-flex align-items-center"
          @click.stop="submit"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
          </div>
          <div v-if="isSubmit">
            <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="ml-1">{{$t('button.accept')}}</span>
          </div>
        </CButton>
        <CButton
          v-if="isEdit"
          color="add"
          class="d-flex align-items-center"
          @click.stop="statusConfirmation(SupplyItem.FgActSupply, insumo.Status, submit)"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
          </div>
          <div v-if="isSubmit">
            <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="ml-1">{{$t('button.accept')}}</span>
          </div>
        </CButton>
        <CButton
          color="wipe"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="resetForm(false)"
        >
          <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>

<script>
// import MovFormValidations from '@/_validations/estiba/MovFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import InsumoFormValidation from '@/_validations/estiba/InsumoFormValidation';
import { GpoSupplyMixin } from '@/_mixins/estiba';
import  ModalMixin  from '@/_mixins/modal';

//Data
function data() {
  return {
    modalActive: this.modal,
    Loading: false,
    ValidationSelect: false,
    isSubmit: false,
    grupos: [],
    GpoSupply: '',
    insumo: {
      SupplyId: '',
      GpoSupplyId: '',
      SupplyName: '',
      Status: 1
    },
  }
}

//Methods
function submit() {
  try{
    this.Loading = true;
    this.isSubmit = true;
    this.ValidationSelect = true;
    if (this.$v.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }
    let metodo = this.isEdit ? 'PUT':'POST';
    let ruta = this.isEdit ? 'Supply-update':'Supply-insert';

    let SupplyJson = this.isEdit ? {
      GpoSupplyId: this.insumo.GpoSupplyId,
      SupplyId: this.insumo.SupplyId,
      SupplyName: this.insumo.SupplyName,
      Status: this.insumo.Status
    } : {
      GpoSupplyId: this.insumo.GpoSupplyId,
      SupplyName: this.insumo.SupplyName
    };
    
    this.$http.ejecutar(metodo, ruta, SupplyJson, { root: 'SupplyJson' })
    .then(response => {
      this.resetForm(true);
      this.notifySuccess({
        text: response.data.data[0].Response,
      });
    }).catch(err => {
      this.notifyError({
        text: err,
      });
    }).then(() => {
      this.isSubmit = false;
      this.Loading = false;
    });
  } catch(e){
    this.isSubmit = false;
    this.Loading = false;
    this.notifyError({text: e})
  } 
  
}

function resetForm(newVal) {
  this.insumo.SupplyId = '';
  this.insumo.SupplyName = '';
  this.insumo.GpoSupplyId = '';
  this.insumo.Status = 1;
  this.ValidationSelect = false;
  this.grupos = [];
  this.$v.$reset();
  this.$emit('submited', newVal);
  this.modalActive = newVal;
}

function getGrupos() {
  this.Loading = true;
  this.$http.get("GpoSupply-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.grupos = response.data.data;
    this.Loading = false;
  }).catch( err => {
    this.Loading = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}

function setInsumoData(insumo) {
  this.insumo.SupplyName = insumo.SupplyName;
  this.insumo.SupplyId = insumo.SupplyId;
  this.insumo.GpoSupplyId = insumo.GpoSupplyId;
  this.insumo.Status = insumo.FgActSupply ? 1 : 0;
  this.ValidationSelect = true;
  this.$v.$touch();
}

//Computeds:
function gruposOptions() {
  if (this.grupos.length==0) {
    return [{
      label: this.$t('label.select'),
      value: '', 
    }];
  }else{
    var chart = [{
      label: this.$t('label.select'),
      value: '', 
    }]
    this.grupos.map(item => {
      chart.push({
        label: item.GpoSupplyName,
        value: item.GpoSupplyId
      })
    })
    return chart;
  }
}

function isGrupoValid() {
  return {
    'invalid-feedback': true,
    'invalido': this.insumo.GpoSupplyId? null : this.$v.$anyDirty? this.$v.insumo.GpoSupplyId.$invalid : ''
  };
}

function statusSelectColor(){
  return this.insumo.Status === 1;
}

function titulo(){
  if(this.isEdit){ 
    let title = this.SupplyItem.SupplyName ? this.SupplyItem.SupplyName : '';
    return this.$t('label.edit')+' '+this.$t('label.supplies')+': '+title;
  }
  else{
    return this.$t('label.nuevo')+' '+this.$t('label.supplies');
  }
}
export default {
  name: 'add-insumos-modal',
  mixins: [
    GpoSupplyMixin,
    ModalMixin
  ],
  props: {
    modal: Boolean,
    isEdit: {
      type: Boolean,
      default: false,
    },
    SupplyItem: {
      type: Object,
      default: () => { }
    }
  },
  data,
  validations: InsumoFormValidation,
  directives: UpperCase,
  methods: {
    setInsumoData,
    getGrupos,
    submit,
    resetForm,
  },
  computed: {
    titulo,
    gruposOptions,
    isGrupoValid,
    statusSelectColor,
  },
  watch: {
    modalActive: function(val){
      if(val){
        this.getGrupos();
        if (this.isEdit) {
          this.setInsumoData(this.SupplyItem);
        }
      }
    }
  }
}
</script>